const INTER = [
  {
    space_src: 'baidujs',
    space_type: 'inters',
    space_id: 'u6990996',
    space_js: '//abpfmzd.hzyizhimei.com/common/bw/source/v/resource/eec_eev.js'
  }];
const BANNER = [
  {
    space_src: 'baidujs',
    space_type: 'native',
    space_id: 'u6990997',
    space_js: '//abpfmzd.hzyizhimei.com/source/cxwf/f_d_ff/openjs/y.js'
  }
];
const NATIVE_1 = [
  {
    space_src: 'baidujs',
    space_type: 'native',
    space_id: 'u6990998',
    space_js: '//abpfmzd.hzyizhimei.com/production/dy/source/xgge/g/g_a.js'
  }
];
const NATIVE_2 = [
  {
    space_src: 'baidujs',
    space_type: 'native',
    space_id: 'u6990999',
    space_js: '//abpfmzd.hzyizhimei.com/site/ezy_h/source/h/resource/fhh/openjs/h.js'
  }
];
const NATIVE_3 = [
  {
    space_src: 'baidujs',
    space_type: 'native',
    space_id: 'u6991000',
    space_js: '//abpfmzd.hzyizhimei.com/source/dy-x/ggh/production/e/static/ee.js'
  }
];


/** 广告位配置 */
export const SPACE_MAP = {
  Home_inter: INTER,
  Home_banner: BANNER,
  Main_native: NATIVE_1,
  Classroom_native: NATIVE_2,
  Wikipedia_cat_native: NATIVE_1,
  Wikipedia_dog_native: NATIVE_2,
  Wikipedia_other_native: NATIVE_3,
  ConstellationList_banner: BANNER,
  ConstellationList_native: NATIVE_1,
  ArticleDetail_inter: INTER,
  ArticleDetail_banner: BANNER,
  ArticleDetail_native: NATIVE_1,
  PetDetail_inter: INTER,
  PetDetail_banner: BANNER,
  PetDetail_native: NATIVE_1,
  Mood_dialog: NATIVE_3,
};
